











































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Invoice, ApiResponse, Contact, Product, InvoiceItem, TaxTotal, Payment, Rate } from '@/interfaces'
import { mapState } from 'vuex'
import { ElForm } from 'element-ui/types/form'
import { paymentStatesList, paymentTypes } from '@/formHelpers'
import ContactForm from './ContactForm.vue'
import FeeList from './Fee/List.vue'
import SendMailForm from '@/components/SendMailForm.vue'
import NoVatForm from '@/components/NoVatForm.vue'
import QuickNav from '@/components/QuickNav.vue' 

interface SendMailData {
  contact: Contact
  customText?: string
  chosenModel?: number | null
}

interface DropdownAction {
  action: string
  invoice?: Invoice
}

interface Totals {
  items: ItemTotal[]
  totalTaxByRate: TaxTotal[]
  totalNoTax: string
  totalWithTax: string
  totalWithoutTax: string
}

interface ItemTotal {
  totalTax: string
  totalWithTax: string
  totalWithoutTax: string
}

interface Structure {
  id: number
  duePeriod: number
  taxRate?: {
    rates?: []
  }
}

export default Vue.extend({
  components: { ContactForm, FeeList, SendMailForm, NoVatForm, QuickNav },
  props: {
    invoice: {
      type: Object as () => Invoice,
      required: false,
      default: () => {
        return {
          structureId: null,
          state: 'proforma',
          paymentState: 'unpaid',
          items: [] as InvoiceItem[],
          payments: [] as Payment[],
        }
      },
    },
    fetchInvoiceId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      showFormModal: false,
      invoiceData: {
        structureId: null,
        state: 'proforma',
        paymentState: 'unpaid',
        items: [] as InvoiceItem[],
        payments: [] as Payment[],
        dueDate: '' as Date | string,
      } as Invoice,
      statesList: [
        { text: 'Emise', value: 'emise' },
        { text: 'Proforma', value: 'proforma' },
        { text: 'Comptabilisée', value: 'comptabilisee' },
      ] as Record<string, string | null>[],
      paymentStatesList,
      paymentTypes,
      userStructure: null as number | null,
      structures: [],
      contacts: [] as Record<string, string | null>[],
      products: [] as Product[],
      newContact: null as Contact | null,
      newContactId: null as string | null,
      showBusinessInfos: false,
      contactInfos: {
        siret: '',
        vat: '',
        fullName: '',
        formName: '',
        address: '',
        email: '',
        phone: '',
        mobile: '',
        companyName: '',
      },
      formModel: {},
      formRules: {},
      viewportWidth: 0,
      displayTooltip: false,
      showAddContactModal: false,
      invoiceNumberLabel: 'Facture',
      generateInvoiceText: 'Générer la facture',
      toPayTitle: 'Net à payer',
      structureId: null,
      vatRates: [] as Rate[],
      resetContactForm: false,
      sendMailData: {} as SendMailData,
      showSendDocumentModal: false,
      invoiceContact: {} as Contact,
      infoPrefill: null as string | null,
      showNoVatModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    showFormModal() {
      // This is used as a trick to reset the form data every time the form modal is shown,
      // as well as for the cancel button, so that it doesn't automatically redirect the user to the product list
      this.resetFormData()
    },
    user(newVal) {
      this.structures = newVal.client.structures
      if (this.invoiceData.structureId === null) {
        this.invoiceData.structureId = newVal.client.structures[0]?.id ?? null
        this.updateDuePeriod(this.invoiceData?.structureId ?? null)
        this.updateVatRates(this.invoiceData?.structureId ?? null)
      }
    },
    fetchInvoiceId(newVal) {
      if (Number.isInteger(parseInt(newVal))) {
        this.getInvoice(newVal)
        if (this.$route.query.add) {
          ;(this.$refs.invoiceItems as InstanceType<typeof FeeList>).addInvoiceItem(null)
        }
      } else {
        this.resetFormData()
      }
    },
    invoiceData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal.parentInvoiceNumber) {
          this.invoiceNumberLabel = 'Avoir'
          this.generateInvoiceText = `Générer l'avoir`
        }
        if (newVal.contact) {
          this.setContactInfos(newVal)
          this.invoiceContact = newVal.contact
        }
        if (newVal.structureId !== this.structureId) {
          this.structureId = newVal.structureId
          this.loadAllProducts()
        }

        Object.keys(this.invoiceData).map((key) => {
          if (key === 'priceExcludingTaxes' || key === 'priceIncludingTaxes') {
            this.invoiceData[key] = this.invoiceData[key]?.toString()
          }
        })
      },
    },
    newContact(newVal: Contact) {
      if (newVal.id) {
        const contact = newVal
        this.contacts.push({
          label: contact.formName ?? null,
          value: contact.id?.toString() ?? null,
        })
        this.newContact = null
        this.invoiceData.contact = contact
        this.setContactInfos(this.invoiceData)
      }
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
      this.invoiceData.structureId = this.user.client.structures[0]?.id ?? null
      this.updateDuePeriod(this.invoiceData?.structureId ?? null)
      this.updateVatRates(this.invoiceData?.structureId ?? null)
    }

    if (Number.isInteger(parseInt(this.fetchInvoiceId))) {
      this.getInvoice(this.fetchInvoiceId)
    } else {
      this.resetFormData()
    }

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    nl2br(str: string): string {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    formatCurrency(number: string | number | null): string {
      if (typeof number === 'string') {
        number = Number(number)
      }
      if (!number) {
        number = 0
      }
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
    },
    showDeleteInvoiceButton() {
      return this.invoiceData.id && this.invoiceData.state === 'proforma'
    },
    showCreateRefundButton() {
      return this.invoiceData.id && (this.invoiceData.state === 'emise' || this.invoiceData.state === 'comptabilisee')
    },
    showRegroupFeesButton() {
      return this.invoiceData.id && this.invoiceData.state === 'proforma'
    },
    showDetachItemsButton() {
      return this.invoiceData.id && (this.invoiceData.state === 'emise' || this.invoiceData.state === 'comptabilisee')
    },
    displayTotals() {
      return this.invoiceData.priceExcludingTaxes && this.invoiceData.priceIncludingTaxes
    },
    displayPayments() {
      return this.invoiceData.id && !this.invoiceData.parentInvoiceId
    },
    displayRefunds() {
      if (this.invoiceData.parentInvoiceNumber) {
        return false
      }
      return this.invoiceData.refunds ? this.invoiceData.refunds.length > 0 : false
    },
    getInvoiceState(): Record<string, string | null> | null {
      return this.statesList.find((state) => state.value === this.invoiceData.state) ?? null
    },
    getInvoicePaymentState(): Record<string, string | null> | null {
      return (
        this.paymentStatesList.find((paymentState) => paymentState.value === this.invoiceData.paymentState) ?? {
          text: 'Non Soldé',
          value: 'unpaid',
          tag: 'danger',
        }
      )
    },
    getInvoiceTag(): string | null {
      const stateObject = this.getInvoicePaymentState()
      if (stateObject) {
        return stateObject.tag
      }
      return 'danger'
    },
    getInvoiceLabel(column: string): string | null {
      if (column === 'state' && this.getInvoiceState()) {
        return this.getInvoiceState()?.text ?? null
      }
      if (column === 'payment_state' && this.getInvoicePaymentState()) {
        return this.getInvoicePaymentState()?.text ?? null
      }
      return null
    },
    getRefundPrice(invoice: Invoice, price: string) {
      if (invoice.state !== 'emise' && invoice.state !== 'comptabilisee') {
        //return this.formatCurrency(0)
        return '-'
      } else {
        return this.formatCurrency(parseFloat(Number(price).toFixed(2)))
      }
    },
    getTvaTotal(): string {
      let tvaAmountArray = this.invoiceData.tvaAmount ? Object.values(this.invoiceData.tvaAmount) : []
      let tvaTotal = 0
      if (tvaAmountArray.length > 0) {
        tvaAmountArray.forEach((tvaAmount: TaxTotal) => {
          tvaTotal = tvaTotal + tvaAmount.value
        })
        return this.formatCurrency(parseFloat(tvaTotal.toFixed(2)))
      }
      return this.formatCurrency(0)
    },
    getInvoiceItemTotals(itemTotals: ItemTotal[], editable = '', index: number | null = null) {
      const invoiceItems = this.invoiceData?.items ?? []
      if (itemTotals?.length == 0 || !invoiceItems) return
      for (let i = 0; i < invoiceItems.length; i++) {
        if (!invoiceItems[i]) continue
        let item = invoiceItems[i]
        item.totalExcludingTaxes = itemTotals[i].totalWithoutTax
        item.totalTaxes = itemTotals[i].totalTax
        item.totalIncludingTaxes = itemTotals[i].totalWithTax
        if (editable === 'nonEditable') {
          item.isEditable = false
        }
        if (index !== null && index === i) {
          if (this.invoiceData?.items && this.invoiceData?.items[index]) {
            this.spliceInvoiceItem(item, index)
          }
        } else {
          this.spliceInvoiceItem(item, i)
        }
      }
    },
    getRemainingPayment() {
      // let reducedAmount = Number(this.invoiceData.priceIncludingTaxes)
      // if (this.invoiceData.payments) {
      //   this.invoiceData?.payments
      //     .filter((payment) => payment.id || (!payment.id && !payment.isEditable))
      //     .forEach((payment) => {
      //       reducedAmount -= Number(payment.amount)
      //     })
      // }
      // if (this.invoiceData.refunds) {
      //   this.invoiceData.refunds.forEach((refund) => {
      //     if (refund.state === 'emise') {
      //       reducedAmount += Number(refund.priceIncludingTaxes)
      //     }
      //   })
      // }
      // if (Math.round(Number(reducedAmount) * 100) / 100 == 0) {
      //   return '0'
      // }
      // return (Math.round(Number(reducedAmount) * 100) / 100).toString()
      if (!this.invoiceData?.remainingPayment) return '0'
      else return (this.invoiceData?.remainingPayment).toString()
    },
    getInvoiceTotals(totals: Totals) {
      this.invoiceData.tvaAmount = totals?.totalTaxByRate
      this.invoiceData.totalNoTax = totals?.totalNoTax
      this.invoiceData.priceExcludingTaxes = totals?.totalWithoutTax.toString()
      this.invoiceData.priceIncludingTaxes = totals?.totalWithTax.toString()
    },
    getRefundTax(invoice: Invoice) {
      let priceWithTax = 0
      let priceWithoutTax = 0
      if (invoice && (invoice.state === 'emise' || invoice.state === 'comptabilisee')) {
        priceWithTax = Number(invoice?.priceIncludingTaxes)
        priceWithoutTax = Number(invoice?.priceExcludingTaxes)
      } else {
        return '-'
      }
      const taxTotal = priceWithTax - priceWithoutTax
      return this.formatCurrency(parseFloat(taxTotal.toFixed(2)))
    },
    resetFormData() {
      this.invoiceData = {
        structureId: null,
        state: 'proforma',
        paymentState: 'unpaid',
        items: [] as InvoiceItem[],
        invoicingDate: new Date(),
      }
    },
    changeStructure(id: number | null) {
      this.updateDuePeriod(id)
      this.updateVatRates(id)
    },
    updateDuePeriod(id: number | null) {
      if (!this.invoiceData.dueDate) {
        const filteredStructure = this.structures
          ? this.structures.filter((structure: Structure) => structure.id === id)
          : []
        if (filteredStructure?.length > 0) {
          const chosenStructure: Structure = filteredStructure[0]
          const duePeriod = chosenStructure.duePeriod ?? 0
          const dueDate = new Date()
          dueDate.setDate(dueDate.getDate() + duePeriod)
          this.invoiceData.dueDate = dueDate
        }
      }
    },
    updateVatRates(id: number | null) {
      if (id) {
        const filteredStructure = this.structures
          ? this.structures.filter((structure: Structure) => structure.id === id)
          : []
        if (filteredStructure?.length > 0) {
          const chosenStructure: Structure = filteredStructure[0]
          this.vatRates = chosenStructure.taxRate?.rates ?? []
        }
      }
    },
    cancelForm() {
      if (this.showFormModal) {
        this.$emit('closeModal')
      } else {
        this.$router.push({ name: 'invoices' })
      }
    },
    handleInvoiceCommand(command: DropdownAction) {
      if (command.action == 'delete' && command.invoice) {
        this.deleteInvoice()
      } else if (command.action == 'createRefund') {
        this.createRefund()
      } else if (command.action == 'regroupFees') {
        this.regroupFees()
      } else if (command.action == 'detachItems') {
        this.detachItems()
      }
    },
    getInvoice(invoiceId: string) {
      this.busy = true
      this.$api
        .get(`/invoice/${invoiceId}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.invoiceData = apiResponse.data.invoice
          this.getInvoiceItemTotals(apiResponse.data.totals.items, 'nonEditable')
          this.getInvoiceTotals(apiResponse.data.totals)
          this.toPayTitle = this.invoiceData.parentInvoiceId ? 'Net à déduire' : 'Net à payer'
          this.$emit('updateInvoiceData', this.invoiceData)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
          if (this.$route.query.add) {
            ;(this.$refs.invoiceItems as InstanceType<typeof FeeList>).addInvoiceItem(null)
          }
        })
    },
    submit: function (newState: string | null) {
      this.busy = true
      ;(this.$refs.myForm as ElForm).validate((valid: boolean) => {
        if (valid) {
          if (!this.invoiceData.id) {
            if (newState === 'emise') {
              this.storeInvoiceWithConfirmation('invoice', newState)
            } else {
              const oldState = this.invoiceData.state
              this.invoiceData.state = newState
              this.storeInvoice('invoice', oldState)
            }
          } else {
            if (newState === 'emise') {
              if (
                this.invoiceData.invoicingDate == null ||
                this.invoiceData.invoicingDate == '' ||
                this.invoiceData.dueDate == null ||
                this.invoiceData.dueDate == ''
              ) {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message: "Merci de renseigner les dates de facturation et d'échéance de la facture pour continuer.",
                })
              } else {
                this.updateInvoiceWithConfirmation('invoice', '', newState)
              }
            } else {
              const oldState = this.invoiceData.state
              this.invoiceData.state = newState
              this.updateInvoice('invoice', '', oldState)
            }
          }
        }
        this.busy = false
      })
    },
    deleteInvoice() {
      let id: number | null = null
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette facture ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
        .then(() => {
          id = this.invoiceData.id ?? null
          this.busy = true
          this.$api.delete(`/invoice/${id}`)
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          if (id !== null) {
            this.$router.back()
          }
          id = null
        })
    },
    generateInvoice(type: string) {
      this.busy = true

      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      this.$api
        .post(`/invoice/${this.invoiceData.id}/generate/${type}`)
        .then(() => {
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/invoice/${this.invoiceData.id}/download?contentd=inline`

          window.open(documentUrl, '_blank')
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          loading.close()
        })
    },
    openSendMailModal() {
      this.showSendDocumentModal = true
      this.sendMailData = {
        contact: this.invoiceContact,
      }
    },
    closeSendMailModal() {
      this.showSendDocumentModal = false
    },
    sendInvoice() {
      this.busy = true

      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      let successMessage = 'La facture '
      if (this.invoiceData.invoiceNumber) {
        successMessage += this.invoiceData.invoiceNumber + ' '
      }
      successMessage += 'a été envoyée.'

      const data = {
        ...this.sendMailData,
      }

      let success = false
      this.$api
        .post(`/invoice/${this.invoiceData.id}/send`, data)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Facture envoyée !',
            message: successMessage,
          })
          success = true
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          loading.close()
          if (success) {
            this.closeSendMailModal()
          }
        })
    },
    updateInvoiceItems(items: InvoiceItem[]) {
      this.invoiceData.items = items
    },
    pushInvoiceItem(newInvoiceItem: InvoiceItem) {
      this.invoiceData.items?.push(newInvoiceItem)
    },
    spliceInvoiceItem(item: InvoiceItem | null, index: number) {
      if (item) {
        this.invoiceData?.items?.splice(index, 1, item)
      } else {
        this.invoiceData?.items?.splice(index, 1)
      }
    },
    storeInvoice(type: string, oldState: string | null = '') {
      const data = this.setInvoiceData(type)

      this.$api
        .post('/invoice', data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.invoiceData = apiResponse.data.invoice
          this.getInvoiceItemTotals(apiResponse.data.totals.items)
          this.getInvoiceTotals(apiResponse.data.totals)
          this.$notify({
            type: 'success',
            title: 'Enregistrement',
            message: 'La facture est crée',
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: apiResponse.message ?? 'Une erreur est survenue. Merci de réessayer plus tard.',
            })
            this.busy = false
            if (oldState !== '') {
              this.invoiceData.state = oldState
            }
          }
        })
        .finally(() => {
          this.busy = false
          if (this.invoiceData.id) {
            let path = `/invoice/${this.invoiceData.id}/edit`
            if (type === 'invoice_item') {
              path = path + '?add=true'
            }
            this.$router.push({ path })
          }
        })
    },
    storeInvoiceWithConfirmation(type: string, newState: string | null = '') {
      this.$confirm(
        'La génération de cette facture est irréversible. Êtes-vous sûr de vouloir la créer ?',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
        .then(() => {
          this.busy = true
          const oldState = this.invoiceData.state
          this.invoiceData.state = newState
          this.storeInvoice(type, oldState)
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
          this.busy = false
        })
        .finally(() => {
          this.busy = false
        })
    },
    updateInvoice(type: string, add: string | null = '', oldState: string | null = '') {
      const data = this.setInvoiceData(type)

      this.$api
        .put(`/invoice/${this.invoiceData.id}`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.invoiceData = apiResponse.data.invoice
          this.getInvoiceItemTotals(apiResponse.data.totals.items)
          this.getInvoiceTotals(apiResponse.data.totals)
          this.$notify({
            type: 'success',
            title: 'Enregistrement',
            message: 'Les modifications sur la fature ont été enregistrées',
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: apiResponse.message ?? 'Une erreur est survenue. Merci de réessayer plus tard.',
            })
            this.busy = false
            if (oldState !== '') {
              this.invoiceData.state = oldState
            }
          }
        })
        .finally(() => {
          this.busy = false
          if (add) {
            ;(this.$refs.invoiceItems as InstanceType<typeof FeeList>).createNewInvoiceItem()
          }
        })
    },
    updateInvoiceWithConfirmation(type: string, add: string | null = '', newState: string | null = '') {
      this.$confirm(
        'La génération de cette facture est irréversible. Êtes-vous sûr de vouloir la créer ?',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
        .then(() => {
          this.busy = true
          const oldState = this.invoiceData.state
          this.invoiceData.state = newState
          this.updateInvoice(type, add, oldState)
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
          this.busy = false
        })
        .finally(() => {
          this.busy = false
        })
    },
    setInvoiceData(type: string) {
      this.invoiceData.items = this.invoiceData?.items?.filter((item) => {
        return item.type === 'emolument_auto' || item.quantity !== 0
      })
      this.invoiceData.payments = this.invoiceData?.payments?.filter(
        (payment) => payment.type !== '' && payment.type !== null
      )
      if (type === 'refund') {
        this.setRefundInfo()
      }
      delete this.invoiceData.refunds
      delete this.invoiceData.project
      return {
        ...this.invoiceData,
      }
    },
    createRefund() {
      if (this.invoiceData?.refunds && this.invoiceData?.refunds !== null && this.invoiceData.refunds.length > 0) {
        this.$notify({
          type: 'warning',
          title: 'Impossible de générer un avoir',
          message: 'Un avoir existe déjà sur cette facture (visible plus bas dans cet écran)',
        })
      } else {
        this.storeInvoice('refund')
      }
    },
    setRefundInfo() {
      this.invoiceData.originInvoiceId = this.invoiceData.id?.toString()
      this.invoiceData.invoiceNumber = null
      this.invoiceData.state = 'proforma'
      this.invoiceData.paymentState = 'unpaid'
      this.invoiceData.payments = []
      this.invoiceData.refunds = []
      delete this.invoiceData.id
    },
    loadAllProducts() {
      this.products = []
      this.$api
        .get('/products', {
          params: {
            page: 'invoiceForm',
            structure: this.invoiceData.structureId,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.data && Array.isArray(apiResponse.data)) {
            this.products = apiResponse.data.filter((product: Product) => {
              if (this.invoiceData.type !== 'decompte' && this.invoiceData.type !== 'bordereau') {
                if (product.type?.includes('_buyer') || product.type?.includes('_seller')) return false
                else return true
              } else {
                return (
                  (!product.type?.includes('_buyer') && this.invoiceData.type === 'decompte') ||
                  (!product.type?.includes('_seller') && this.invoiceData.type === 'bordereau')
                )
              }
            })
          }
        })
    },
    addInvoicePayment() {
      const newPayment: Payment = {
        type: '',
        amount: this.getRemainingPayment(),
        note: '',
        date: new Date(),
        isEditable: true,
      }
      this.invoiceData.payments?.push(newPayment)
    },
    canEditPayment(index: number) {
      const payment = this.invoiceData.payments ? this.invoiceData.payments[index] : null
      return payment?.isEditable
    },
    changeInvoicePaymentState(index: number, state: string) {
      if (this.invoiceData.payments) {
        const payment = this.invoiceData.payments[index]
        const hasEditableItem = this.invoiceData?.payments.filter((payment) => payment.isEditable === true)
        if (hasEditableItem.length > 0 && hasEditableItem[0] !== payment) return

        if (payment && payment.type) {
          if (state === 'isEditable' && !payment.isEditable) {
            payment.isEditable = true
          } else if (state === 'isNonEditable' && payment.isEditable) {
            payment.isEditable = false
            if (!payment.id && !this.invoiceData.id) {
              this.storeInvoice('payment')
            } else {
              this.updateInvoice('payment')
            }
          }
          this.invoiceData.payments?.splice(index, 1, payment)
        }
      }
    },
    deleteInvoicePayment(index: number, itemId: number) {
      if (!itemId) {
        this.invoiceData.payments?.splice(index, 1)
      } else {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer ce règlement ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            const id = this.invoiceData.id
            if (id) {
              this.busy = true
              this.$api.delete(`/invoice/${id}/payment/${itemId}`).then((response) => {
                // const apiResponse = response.data as ApiResponse
                // this.invoiceData = apiResponse.data
                // this.$notify({
                //   type: 'success',
                //   title: 'Succès',
                //   message: 'Opération réalisée avec succès !',
                // })
                this.getInvoice(id.toString())
              })
            }
          })
          .catch((error) => {
            if (error !== 'cancel') {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    regroupFees() {
      if (!this.busy && this.invoiceData.id) {
        this.busy = true
        this.$api
          .post(`/invoice/${this.invoiceData.id}/regroup-fees`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data) {
              //this.invoiceData = apiResponse.data.invoice
              //this.invoiceData.items = apiResponse.data.items
              //this.getInvoiceItemTotals(apiResponse.data.totals.items)
              //this.getInvoiceTotals(apiResponse.data.totals)
              const id = this.invoiceData.id?.toString() ?? ''
              this.getInvoice(id)
            }
          })
          .catch((error) => {
            if (!error.response?.data?.notification) {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée : ' + error,
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    detachItems() {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir détacher les lots de cette facture ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api.put(`/invoice/${this.invoiceData.id}/detach-items`).catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.',
          })
        })
      })
    },
    searchContacts(search?: string, minLength = 2) {
      if (search == '*' || (search && search.length >= minLength)) {
        if (search == '*') {
          search = ''
        }

        this.infoPrefill = search

        this.contacts = []
        this.loading = true
        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data && Array.isArray(apiResponse.data)) {
              apiResponse.data.forEach((contact: Contact) => {
                let contactLabel = contact.formName
                if (contact.city) {
                  contactLabel = `${contactLabel} - ${contact.city}`
                }
                this.contacts.push({
                  label: contactLabel ?? null,
                  value: contact.id?.toString() ?? null,
                })
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.contacts = []
      }
    },
    openContactForm() {
      if (!this.busy) {
        this.resetContactForm = true
        this.showAddContactModal = true
      }
    },
    closeContactForm() {
      this.showAddContactModal = false
      this.clearNewContact()
      this.invoiceData.contactName = null
      this.infoPrefill = null
    },
    clearNewContact() {
      this.newContactId = ''
      this.invoiceData.contact = null
      this.invoiceData.savedContactId = null
      this.clearContactInfos()
      this.resetContactForm = !this.resetContactForm
    },
    updateNewContact(contact: Contact) {
      this.showAddContactModal = false
      this.saveContactInfos(contact)
    },
    saveContactInfos(contact: Contact) {
      this.invoiceData.contact = contact
      if (this.invoiceData.id) {
        this.updateInvoice('invoice', '', this.invoiceData.state)
      } else {
        this.storeInvoice('invoice', this.invoiceData.state)
      }
    },
    setContactInfos(invoice: Invoice | null) {
      let address = this.getInvoiceContactAddress(invoice)

      this.showBusinessInfos = false

      if (invoice?.contactType == 'personne_morale') {
        this.showBusinessInfos = true
      }

      if (this.invoiceData.savedContactId) {
        this.contactInfos = {
          siret: invoice?.contactSiret ?? '-',
          vat: invoice?.contactVat ?? '-',
          fullName: invoice?.contactName ?? '-',
          formName: invoice?.contactName ?? '-',
          address,
          email: invoice?.contactEmail ?? '-',
          phone: invoice?.contactPhone ?? '-',
          mobile: invoice?.contactMobile ?? '-',
          companyName: invoice?.contactCompanyName ?? '-',
        }
      }
      if (invoice?.vatStatement === null) {
        this.invoiceData.vatStatement = this.invoiceData.contact?.vatStatement
      }
    },
    getInvoiceContactAddress(invoice: Invoice | null) {
      let address = ''
      if (invoice?.contactAddress) {
        address = `${invoice?.contactAddress ?? ''}`
      }
      if (invoice?.contactPostalCode) {
        address = `${address} ${invoice?.contactPostalCode ?? ''}`
      }
      if (invoice?.contactCity) {
        address = `${address} ${invoice?.contactCity ?? ''}`
      }
      if (invoice?.contactCountry) {
        address = `${address} ${invoice?.contactCountry ?? ''}`
      }
      if (address.trim() === '') {
        return '-'
      }

      return address
    },
    clearContactInfos() {
      this.contactInfos = {
        siret: '-',
        vat: '-',
        fullName: '-',
        formName: '-',
        address: '-',
        email: '-',
        phone: '-',
        mobile: '-',
        companyName: '-',
      }
    },
    loadContact(id: string) {
      if (id === 'add') {
        this.openContactForm()
      } else {
        this.loadContactInfo(id)
      }
    },
    loadContactInfo(id: string) {
      if (id == '') {
        // this.contactInfos = this.setContactInfos(null)
        return
      }
      this.busy = true
      this.$api
        .get(`/contact/${id}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.saveContactInfos(apiResponse.data)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    updateInvoiceData(noVatValue: string | null) {
      if (noVatValue) {
        this.invoiceData.noVat = true
        this.invoiceData.noVatStatement = noVatValue
        const oldState = this.invoiceData.state
        this.updateInvoice('invoice', '', oldState)
      }
      this.showNoVatModal = false
    },
  },
})
